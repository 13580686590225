.vision-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
  background: #fff;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
  width: 100%;
  .vision-card-img {
    width: 15vw;
    height: 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .vision-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      color: var(--primary-color-light);
      font-weight: 700;
    }
    h6 {
      color: var(--primary-color);
      text-align: center;
      font-size: 1rem;
    }
  }
}
@media screen and (max-width:575px) {
  .vision-card{
    padding: 2rem 1rem;
    width: 100%;
    .vision-card-img{
      width: 40vw;
    }
    .vision-card-content{
      padding-top: 1rem;
    }
  }
}