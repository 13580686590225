.vision {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10rem 0;

  .vision-header {
    margin-bottom: 2rem;
  }

  .vision-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 2rem;
    width: 100%;
  }
}

@media screen and (max-width:575px) {
  .vision {
    padding: 2rem 0;

    .vision-cards-container {
      padding-top: 2rem;
      flex-direction: column;
    }
  }
}