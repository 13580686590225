.contact-us {
  display: flex;
  flex-direction: column;
  padding: 8rem 0;
  padding-bottom: 0rem;
  .contact-us-wrapper {
    display: flex;
    // width: 73.4vw;
    // margin-inline: auto;
    align-items: center;
    background: #fff;
    padding: 2rem;
    border-radius: 32px;
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    -ms-border-radius: 32px;
    -o-border-radius: 32px;
    gap: 2rem;
    .contact-us-form {
      display: flex;
      flex-direction: column;
      width: 40%;
      position: relative;
      .section-title {
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 1rem;
        h3{
          font-size: 2.5rem !important;
        }
      }
      form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        textarea,
        input {
          display: flex;
          width: 100%;
          padding: 1rem;
          align-items: center;
          gap: 10px;
          border-radius: 16px;
          background: #f1f3fb;
          &::placeholder {
            color: #abb6ca;
            font-weight: 400;
          }
        }
        textarea {
          resize: none;
        }
        button {
          width: 100%;
        }
        .contact-us-error {
          color: red;
        }

                .SendMessage{
                  border-radius: 7.5rem;
                    background: #4059C9;
                    box-shadow: 0px 26px 50px 0px rgba(64, 89, 201, 0.26);
                    display: flex;
                   color: #fff;
                      padding: 1rem;
                      justify-content: center;
                      align-items: center;
                   
                }
      }
    }
    .map-wrapper {
      width: 60%;
    }
    .contact-us-img {
      display: flex;
      flex-direction: column;
      width: 50%;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 3/2;
        border-radius: 1rem;
        -webkit-border-radius: 1rem;
        -moz-border-radius: 1rem;
        -ms-border-radius: 1rem;
        -o-border-radius: 1rem;
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .contact-us-wrapper {
    width: 90vw;
    flex-direction: column;
    gap: 2rem;
padding: 1rem !important;
    .contact-us-form {
      width: 100% !important;
      align-items: center;
    }
    .contact-us-img {
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 992px) {
  .contact-us-wrapper {
    flex-direction: column;
    gap: 2rem;
    .contact-us-form {
      width: 100% !important;
      align-items: center;
    }
    .contact-us-img {
      width: 100% !important;
    }
  }
  .contact-us .contact-us-wrapper .map-wrapper{
    width: 100%;
  }
}
