.App {
  height: 100%;
  .all-app-container {
    width: 74.4vw;
    margin: 0 auto;

    @media (max-width: 767px) {
      width: 90vw;
    }
  }
}
