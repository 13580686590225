.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  color: var(--primary-color-light);
  font-weight: 700;
  font-size: 1rem !important;
  width: 30%;
  border-radius: 3rem;
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  -ms-border-radius: 3rem;
  -o-border-radius: 3rem;
  border: 2px solid var(--primary-color-light);
  background: transparent;
}
.primary-button {
  border-radius: 49px;
  color: #fff;
  background: var(--primary-color-light);
  box-shadow: 0px 0px 41px 0px rgba(64, 89, 201, 0.44);
  -webkit-border-radius: 49px;
  -moz-border-radius: 49px;
  -ms-border-radius: 49px;
  -o-border-radius: 49px;
}

@media screen and (max-width: 992px) {
  .primary-button,
  .button {
    width: auto;
    font-size: 0.8rem !important;
  }
}

@media screen and (min-width: 1600px) {
  .primary-button,
  .button {
    width: 35%;
    height: 4rem;
    border: 3px solid var(--primary-color-light);
    font-size: 1.5rem !important;
  }
}
@media screen and (min-width: 1921px) {
  .primary-button,
  .button {
    height: 5rem;
  }
}
