.about-us {
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/img/AboutBG.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4rem 0;
  margin-inline-start: -13.3vw;
  width: 100vw;

  .about-us-wrapper {
    width: 73.4vw;
    margin-inline: auto;
    display: flex;
    align-items: center;

    .about-us-content {
      width: 50%;

      color: #273a5c;
      font-weight: 400;

      p {
        &:last-of-type {
          margin-bottom: 2rem;
        }
      }

      .about-us-btns {
        display: flex;
        gap: 0.8rem;
      }
    }

    .about-us-img {
      width: 50%;

      img {
        width: 100%;
        height: 100%;
        aspect-ratio: 3/2;
      }
    }
  }
}


@media screen and (max-width:768px) {

  .about-us {
    margin-inline-start: -5vw;

    .about-us-wrapper {
      flex-direction: column;
      gap: 2rem;

      .about-us-content {
        width: 100%;
      }
    }
  }
}


@media screen and (max-width:800px) {
  .about-us {
    .about-us-wrapper {
      flex-direction: column;
      gap: 2rem;

      .about-us-content {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width:1600px) {
  .about-us {
    .about-us-content {
      font-size: 1.5rem !important;
    }
  }
}