.market-card {
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  background: #fff;
  padding: 0.5rem;
  cursor: pointer;
  .market-card-img {
    img {
      filter: saturate(0);
      width: 100%;
      height: 100%;
      aspect-ratio: 3/2;
      border-radius: 1.25rem;
      object-fit: cover;
      object-position: center 11%;
    }

  }
  .market-card-content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    .market-card-subtitle {
      margin-bottom: 3px;
      color: var(--primary-color-light);
    }
    .market-card-title {
      color: #080808;
      margin-bottom: 8px;
    }
    .market-card-desc {
      color: #808080;
    }
  }
  &:hover {
    box-shadow: 0px 35px 76px -5px rgba(64, 89, 201, 0.14);
    transition: all .6s;
    -webkit-transition: all .6s;
    -moz-transition: all .6s;
    -ms-transition: all .6s;
    -o-transition: all .6s;
    img{
        filter: saturate(1);
        transition: 1.2s;
      }
}
}
@media screen and (min-width: 1600px) {
  .market-card {
    .market-card-content {
      .market-card-subtitle {
        font-size: 1.5rem;
      }
      .market-card-title {
        font-size: 1.5rem;
      }
      .market-card-desc {
        font-size: 20px;
      }
    }
  }
}
