@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
:root {
  --primary-color: #1a315a;
  --primary-color-light: #4059c9;
  --secondary-color: #49608a;
  --secondary-color-light: #a8adc7;
  --paragraph-color: #6a7995;
  --secondary-paragraph-color: #989ba1;
  --gray-color: #202020;
  --gray-color-light: #101010;
  --placeholder-color: #abb6ca;
  --navbar-list-item-color: #5d5a88;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  scroll-behavior: smooth !important;
}

html {
  scroll-behavior: smooth !important;
}
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

body {
  scroll-behavior: smooth;
  font-weight: 600;
  line-height: 1.6;
  font-family: "Ubuntu", sans-serif !important;
  background: #fafcff !important;
}
::-webkit-scrollbar {
  width: 12px;
  right: 2vw;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #dbe5ff;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #dbe5ff;
}
@media screen and (min-width: 1600px) {
  body {
    font-size: 18px !important;
  }
}
