.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  background: transparent;
  position: relative;
  width: 100%;
  height: 5.5vw;
  position: fixed;
  z-index: 2000;
  padding-inline-end: 10vw;
  top: 1rem;
  padding-inline-start: 12.3vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 250;
    background: rgba(255, 255, 255, 0.95);
    transition: all 0.3s ease;
    
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    // padding-inline: 13vw;
    padding-inline-end: 12.5vw;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

  .header-logo-container {
    width: 10rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
    @media (max-width: 768px) {
      width: 6rem;
    }
  }
  .mobile-menu-overlay,
  .mobile-menu {
    display: none;
  }
  .header-menu-list {
    position: relative;
    z-index: 10;
    width: 32.5vw;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    position: relative;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 32vw;
    padding-inline: 1vw;

    .header-menu-item-link {
      cursor: pointer;
      text-decoration: none;
      color: var(--secondary-color-light);
      font-size: 1.1rem;
      cursor: pointer;
    }

    .active-link {
      color: var(--navbar-list-item-color);
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      font-weight: bold;
      position: relative;

      &::before {
        content: " ";
        position: absolute;
        width: 80%;
        height: 0.2rem;
        left: 10%;
        background: var(--navbar-list-item-color);
        top: 120%;
        border-radius: 1rem;
        -webkit-border-radius: 1rem;
        -moz-border-radius: 1rem;
        -ms-border-radius: 1rem;
        -o-border-radius: 1rem;
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .header {

    gap: 1.2rem;

  }
}

@media screen and (max-width: 850px) {
  .header {
      top: 0rem;
    &.fixed-header {
      padding-inline-end: 8vw;
      padding-inline-start: 8vw;
    }
    .header-menu-list {
      display: none;
    }
    .mobile-menu {
      display: block;
      cursor: pointer;
    }
    .mobile-menu-overlay {
      height: 100%;
      width: 0;
      position: fixed;
      z-index: 1;
      top: 0;
      right: 0;
      background: #fafcff;
      box-shadow: -8px 0px 66px -5px rgba(0, 0, 0, 0.25);
      overflow-x: hidden;
      transition: 0.5s;
      display: block;
      .closebtn {
        text-decoration: none;
        width: 2rem;
        height: 2rem;
        font-size: 2rem;
        color: #000;
        display: block;
        transition: 0.3s;
        position: absolute;
    right: 7vw;
      top: 4vw;
      cursor: pointer;
      }
      .mobile-menu-overlay-content{
        display: flex;
        flex-direction: column;
        gap: 3rem;
        width: 100%;
        align-items: flex-start;
        padding: 0 2rem;
        display: flex;
        padding-top: 5rem;
        a{
          font-size: 1.5rem !important;
          color: #A8ADC7;
          text-transform: capitalize;
          text-decoration: none;
          cursor: pointer;
        }
        .active-link {
          color: var(--navbar-list-item-color);
          transition: all 0.2s ease-in-out;
          -webkit-transition: all 0.2s ease-in-out;
          -moz-transition: all 0.2s ease-in-out;
          -ms-transition: all 0.2s ease-in-out;
          -o-transition: all 0.2s ease-in-out;
          position: relative;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .header {
    padding-inline-end: 6vw;
    .header-menu-list {
      width: 36vw;
    }

    .header-menu-item-link {
      font-size: 0.8rem !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .header {
    .header-menu-list {
      top: 2vw !important;

      .header-menu-item-link {
        font-size: 0.8rem !important;
      }
    }
  }
}

@media screen and (max-width: 1224px) {
  .header {
    height: 70px;

    .header-menu-list {
      top: -1vw !important;

      .header-menu-item-link {
        font-size: 0.65rem !important;
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .header {
    .header-menu-list {
      .header-menu-item-link {
        font-size: 1.25rem;
        cursor: pointer;
      }
    }
  }
}
