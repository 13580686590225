.map-tooltip {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  align-items: center;
  gap: 2rem;
  border-radius: 20px;
  background: #fff;
  margin-bottom: 2rem;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  z-index: 1;
  border: 1px solid #eaedff;
  .map-tooltip-content {
    p {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }
}
