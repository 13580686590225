.error-page {
background-size: auto;
  background-repeat: no-repeat;
  margin: 1rem;
  height: 96vh;
  border-radius: 2rem;
  overflow: hidden;
  .hero{
    background-image: none;
  }
  .header-menu-list{
    padding-inline-end: 2rem !important;
  }
  background-image: url('../../assets/img/404BG.webp');
  background-size: cover;
  .error-page-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0;
    img {
      width: 30%;
    }
    h1 {
      font-size: 4rem;
      color: #313131;
      font-weight: bold;
    }
    p {
      color: #262626;
    }
    button {
      font-size: 1rem !important;
      width: 20%;
      height: 3rem;
    }
  }
}