.partner {
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-image: url("../../assets/img/Map.webp");
  background-position: top;
  background-size: 100vw;
  background-repeat: no-repeat;
  display: flex;
  align-items: start;
  padding-top: 4rem;
  justify-content: center;
  height: 55vw;
  margin-inline-start: -13.3vw;
        width: 100vw;

  @media (max-width: 768px) {
    padding-top: 0;
    height: 60vh;
      background-position: 50% 70%;
      width: 100vw;
      padding-bottom: 2rem;
      background-size: 130vw;
      position: relative;
      z-index: 99999;
        margin-inline-start: -5vw;
  }
}