.services {
  padding-top: 7rem;
  display: flex;
  flex-direction: column;

  .services-container {
    // padding-inline: 13vw;

    margin-inline: auto;
  }

  .services-header {
    margin-bottom: 2.2rem;
  }

  .services-content-container-two {
    display: flex;
    gap: 1rem;
    overflow: hidden;
  }

  // .services-content-container-two,
  // .services-content-container-one {
  //   display: flex;
  //   gap: 1rem;
  //   overflow: hidden;
  // }
  .services-content-container-one {
    margin-bottom: 1rem;
    overflow: hidden;
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
}

@media screen and (max-width: 575px) {
  .services {
    .services-header {
      padding: 0 3rem;

      .section-title-desc {
        text-align: center;
      }
    }

    .services-container {
      padding: 0 !important;
    }

    .services .services-content-container-two,
    .services .services-content-container-one {
      gap: 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .services {
    padding-top: 4rem;
  }

  .services-content-container-two,
  .services-content-container-one {
    flex-wrap: wrap;
  }
}