.partnership-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: #fff;
  padding: 1rem;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
  width: 100%;
  .partnership-card-img {
    border-radius: 24px;
    background: rgba(185, 194, 255, 0.1);
    width: 13vw;
    padding: 1rem 2rem;
    img {
      width: 100%;
      height: 100%;
      aspect-ratio: 3/2;
    }
  }
  .partnership-card-content {
    h4 {
      margin: 0;
      color: var(--primary-color-light);
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 575px) {
  .partnership-card {
    width: 100%;
    .partnership-card-img {
      padding: 0 !important;
    }
  }
}
@media screen and (max-width: 850px) {
  .partnership-card {
    justify-content: flex-start;
    .partnership-card-img {
      width: 30vw;
      padding: 0 !important;
    }
  }
}
@media screen and (max-width: 992px) {
  .partnership-card {
    .partnership-card-img {
      padding: 1rem 2rem;
    }
  }
}
@media screen and (min-width: 1600px) {
  .partnership-card {
    gap: 2rem;
  }
}
