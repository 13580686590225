.footer {
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  padding-top: 4rem;
  .footer-wrapper {
    display: flex;
    flex-direction: column;
    .footer-links {
      display: flex;
      flex-direction: column;
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        gap: 4rem;
        li {
          a {
            text-decoration: none;
            color: var(--primary-color);
          }
        }
      }
    }
    .footer-socials-copyright {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .copyright {
        p {
          a {
            font-style: italic;
            text-decoration: none;
            color: var(--primary-color-light);
          }
        }
      }
      .footer-socials {
        ul {
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;
          gap: 1.5rem;

          li {
            a {
              color: #000;
              svg {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
      }
    }
  }
}
