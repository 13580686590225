.markets {
  margin-bottom: 8rem;

  .markets-container {
    // padding-inline: 13vw;
    margin-inline: auto;
  }

  .markets-header {
    margin-bottom: 3rem;
  }

  .markets-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    column-gap: 2rem;
    row-gap: 1rem;
  }
}

@media screen and (max-width: 575px) {
  .markets-cards-container {
    grid-template-columns: auto !important;
  }
}

@media screen and (min-width: 1600px) {
  .markets-cards-container {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}