.services-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem;
  background: #fff;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
  .card-img {
    width: 100%;
    margin-bottom: 2rem;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .card-content {
    display: flex;
    flex-direction: column;
    h4 {
      color: var(--primary-color);
      font-weight: 700;
      margin-bottom: 1rem;
    }
    p {
      color: var(--paragraph-color);
      font-size: 1rem;
      font-weight: 400;
      text-wrap: wrap;
    }
  }
}
@media screen and (min-width: 1600px) {
  .services-card {
    .card-content {
      p {
        font-size: 1.125rem !important;
      }
    }
  }
}
