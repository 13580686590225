.privacy-policy {
  .scrollable-content {
    height: 60vh;
    padding-inline-end: 2rem;
    overflow-y: scroll;
    padding-bottom: 30rem;
  }

  ul {
    padding: 0 !important;
    margin: 0 !important;
    margin-bottom: 1rem !important;
  }

  .privacy-container {
    padding-inline: 13.3vw;
    margin-inline: auto;
    padding-top: 5vw;
  }

  .privacyPolicyTabsDataItem-title {
    color: var(--primary-color-light);
    font-weight: bold;
    text-transform: capitalize;
  }

  .nav-pills {
    position: relative;

    .nav-link {
      color: rgba(32, 46, 112, 0.64);
      padding-inline: 0;
    }

    .nav-link.active {
      position: relative !important;
      background-color: transparent !important;
      border: none !important;
      color: var(--primary-color) !important;
      padding-inline-start: 0;
      font-weight: bold !important;

      &::after {
        content: " ";
        position: absolute;
        background: var(--primary-color-light);
        width: 100%;
        height: 3px;
        border-radius: 32px;
        -webkit-border-radius: 32px;
        -moz-border-radius: 32px;
        -ms-border-radius: 32px;
        -o-border-radius: 32px;
        bottom: 0vw;
        left: 0;
      }
    }

  }

  // .tab-content>.active {
  //   margin-top: 3rem;
  // }

  .mobile-privacyPolicy {
    width: 90vw;
    margin-inline: auto;

    .privacyPolicy-nav {
      color: #202e70;
      font-family: "Ubuntu", sans-serif;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: -0.0225rem;
      border-bottom: 2px solid #4260ed;
    }

    .privacyPolicy-title {
      color: #4260ed;

      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      /* 1.4rem */
      letter-spacing: -0.0175rem;
    }

    .privacyPolicy-desc {
      color: #434343;

      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      letter-spacing: -0.0175rem;
      margin-bottom: 2rem;
    }
  }
}