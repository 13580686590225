.partnership {
  padding-top: 0rem;
  padding-bottom: 8rem;
  .partnership-header {
    margin-bottom: 3rem;
  }
  .partnership-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h5 {
      &:first-of-type {
        color: var(--gray-color-light);
        font-weight: 400;
      }
    }
    .partnership-cards-wrapper {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      gap: 2rem;
      width: 100%;
    }
  }
}
@media screen and (max-width:575px) {
  .partnership{
    .partnership-cards-container{
      h5{
        text-align: center;
        padding: 0 2rem;
      }
      .partnership-cards-wrapper{
        gap: 2rem !important;
        flex-direction: column;
      }
    }
  }
}
@media screen and (max-width:850px) {
  .partnership{
    .partnership-cards-container{
      h5{
        text-align: center;
        padding: 0 2rem;
      }
      .partnership-cards-wrapper{
        gap: 2rem !important;
        flex-direction: column;
      }
    }
  }
}