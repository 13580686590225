.contact-tooltip {
  position: relative;
  padding: 0.5rem;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid #eaedff;
  background: #fff;
  .contact-tooltip-wrapper {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 0.5rem 1rem;
    padding-left: 0.5rem;
    background: #fff;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    p {
      margin-bottom: 0;
    }
    .contact-tooltip-img {
      display: flex;
      width: 46px;
      justify-content: center;
      align-items: center;
      border-radius: 11px;
      img {
        width: 100%;
        aspect-ratio: 3/2;
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .contact-tooltip {
    width: 100%;
    left: 0;
    .contact-tooltip-img {
      padding: 0 !important;
    }
  }
}
