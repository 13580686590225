.history-card-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  .img-wrapper {
    width: 70vw;
    display: flex;
    height: 55vh;
    position: relative;
    z-index: 99;

    @media (max-width: 768px) {
      justify-content: center;

      height: 40vh;
          width: 100%;
    .histoy-card-img {
            box-shadow: 0px 83px 28px -46px rgba(0, 0, 0, 0.25) !important;
                  width: 100% !important;
    }
    }

    .histoy-card-img {
      width: 92%;
      height: 85%;

      -webkit-border-radius: 24px;
      -moz-border-radius: 24px;
      -ms-border-radius: 24px;
      -o-border-radius: 24px;
      border-radius: 24px;
      box-shadow: 0px 83px 48px -56px rgba(0, 0, 0, 0.25);
    }
  }

  .history-card-timelines-wrapper {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    gap: 1rem;
    height: 100%;

    .history-card-timeline {
      display: flex;
      gap: 1rem;
      flex-grow: 1;
      height: 100%;
      cursor: pointer;

      &:last-of-type {
        position: relative;
      opacity: .5;

      }
      &:only-child{

      opacity: 1;

      }

      .history-card-timeline-content {
        h5 {
          color: var(--primary-color-light);

          &:first-of-type {
            color: var(--primary-color);
          }
        }

        p {
          color: var(--paragraph-color);
          margin-bottom: 0;
        }
      }
    }
  }

  .history-card-timeline-rounder {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--primary-color-light);
    border-radius: 50%;
  }

  .history-card-timeline-line {
    display: block;
    width: 1px;
    height: 100%;
    transform: translate(6px, -7px);
    -webkit-transform: translate(6px, -7px);
    -moz-transform: translate(6px, -7px);
    -ms-transform: translate(6px, -7px);
    -o-transform: translate(6px, -7px);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%234059C9FF' stroke-width='8' stroke-dasharray='14' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  }

  @media (max-width: 768px) {
    gap: 2rem;
    flex-direction: column-reverse;
  }
}

@media screen and (min-width: 1600px) {
  .history-card-timeline-content {
    h5 {
      font-size: 2rem;
    }
  }

  .history-card-wrapper {
    .history-card-timeline-rounder {
      width: 1rem;
      height: 1rem;
    }

    .history-card-timeline-line {
      transform: translate(8px, -7px);
      -webkit-transform: translate(8px, -7px);
      -moz-transform: translate(8px, -7px);
      -ms-transform: translate(8px, -7px);
      -o-transform: translate(8px, -7px);
    }
  }

}