.HeroBackgroundWithoutImage {
  background-image: url("../../assets/img/PrivacyHero.webp");
  height: 25vw;
  background-size: 100vw auto;
}

.HeroBackgroundWithImage {
  background-image: url("../../assets/img/HeroBGMain.webp");
  background-size: 100vw auto;
  height: 48vw;
}

.hero {
  position: relative;
  background-position: top;
  background-repeat: no-repeat;
  margin: 1rem;
  display: flex;
  align-items: center;
  padding-inline-start: 13.3vw;

  border-radius: 32px;
  overflow: hidden;

  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;

  .hero-content {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    align-items: center;
  }

  .hero-content-article {
    width: 45%;
    margin-inline: -1.5rem;
    h3 {
      color: var(--primary-color);
      margin-bottom: 1rem;
      font-weight: bold;
    }

    P {
      font-weight: 400;
      margin-bottom: 2rem;
      color: var(--secondary-color);
      line-height: 1.6rem;
    }

    .hasUnderline {
      position: relative;

      &::after {
        content: " ";
        position: absolute;
        background: var(--primary-color-light);
        width: 50%;
        height: 3px;
        border-radius: 32px;
        -webkit-border-radius: 32px;
        -moz-border-radius: 32px;
        -ms-border-radius: 32px;
        -o-border-radius: 32px;
        bottom: -1vw;
        left: 0;
      }
    }

    .hero-content-article-btns {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  img {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    object-fit: cover;
    position: relative;
    z-index: 2;
  }

  #VITELAVIE {
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}

@media screen and (max-width: 675px) {
  .hero-img {
    width: 100% !important;
  }
}

@media screen and (max-width: 850px) {
  .hero {
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    padding: 0 !important;
    height: auto !important;
    background-image: url("../../assets/img/HeroSectionMobile.webp");
    // background-size: contain;
    flex-direction: column !important;
    padding-bottom: 1rem;
margin-top: 4rem;
    .hero-content-article {
          margin-top: 60vw;
      height:auto;
      width: 100% !important;
      order: 2;
      margin-inline-start: -1rem;
        align-self: start;
    }

    .hero-img {
      width: 100% !important;

    }
  }
}

@media screen and (max-width: 992px) {
  .hero {
    height: auto;

    // background-size: 108vw auto !important;
  }

  .hero-content {
    height: auto !important;
  }

  .hero-content-article {
    align-self: self-end;
    width: 70% !important;
    h3 {
      font-size: 1.2rem;
    }
    p {
      font-size: 0.8rem;
      margin-bottom: 1rem !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .hero {
    height: auto;
  }

  .hero-content {
    height: auto !important;
  }

  .hero-content-article {
    h3 {
      font-size: 1.5rem;
    }

    p {
      font-size: 0.8rem;
      margin-bottom: 1.5rem !important;
    }
  }
}

@media screen and (max-width: 1124px) {
  .hero {
    height: auto;
  }

  .hero-content {
    height: auto !important;
  }

  .hero-content-article {
    h3 {
      font-size: 1.5rem;
    }

    p {
      font-size: 0.8rem;
      margin-bottom: 1.5rem !important;
    }
  }
}

@media screen and (max-width: 1350px) {
  .hero {
    height: 50vw;
  }

  .hero-content {
    height: auto !important;
  }

  .hero-content-article {
    padding: 2rem !important;

    p {
      line-height: 1.3;
    }
  }
}

@media screen and (min-width: 1600px) {
  .hero-content-article {
    height: auto !important;

    // padding-top: 7vw;
    // margin-top: -4rem;
    h3 {
      font-size: 2rem !important;
    }

    p {
      font-size: 1.125rem;
      line-height: 2.2rem !important;
    }
  }
}

@media screen and (min-width: 1800px) {
  .hero-content-article {
    height: auto !important;

    h3 {
      font-size: 2.5rem !important;
    }

    p {
      font-size: 1.125rem;
      line-height: 2.2rem !important;
    }
  }
}

@media screen and (min-width: 1921px) {
  .hero {
    // height: 100vh;
  }

  .hero-content-article {
    height: auto !important;

    h3 {
      font-size: 2.5rem !important;
    }

    p {
      font-size: 1.125rem;
      line-height: 2.2rem !important;
    }
  }
}
