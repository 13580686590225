.history {
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  // padding-inline: 13vw;
  margin-inline: auto;
  gap: 3rem;
  margin-bottom: 8rem;

  .section-title {}

  .history-slider-container {
    height: 100%;
  }

  .slick-prev {
    position: absolute;
    right: 22%;
    left: auto !important;
    z-index: 1000;
    top: 85%;

    img {
      width: 3rem;
      height: 3rem;
      position: absolute;
      bottom: 1rem;
      left: -2rem;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .slick-next {
    position: absolute;
    right: 16%;
    left: auto !important;
    z-index: 1000;
    top: 85%;

    img {
      width: 3rem;
      height: 3rem;
      position: absolute;
      bottom: 1rem;
      left: 1rem;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    display: none !important;
  }

  .slick-prev:before {
    content: "";
  }

  .slick-next:before {
    content: "";
  }
}

@media screen and (max-width: 575px) {
  .history {
    padding-top: 0rem;

    .section-title {
      align-items: center;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 850px) {
  .history {
    padding-inline: 1.5vw;
    // padding: 4rem 0;
  }
}

@media screen and (max-width: 1440px) {
  .history {
    height: auto;
  }
}