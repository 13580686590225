.section-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  .section-title-subTitle {
    color: var(--primary-color-light);
    text-transform: capitalize;
    font-weight: 700;
  }
  .section-title-main {
    color: var(--primary-color);
    text-transform: capitalize;
    font-weight: 700;
  }
  .section-title-main-underline {
    color: var(--primary-color);
    text-transform: capitalize;
    font-weight: 700;
    position: relative;
    &::after {
      content: " ";
      position: absolute;
      width: 30%;
      height: 0.2rem;
      top: 140%;
      left: 35%;
      background: var(--primary-color-light);
    }
  }
  .section-title-desc {
    color: var(--secondary-paragraph-color);
    text-transform: capitalize;
  }
}
@media screen and (min-width:1600px) {
  .section-title-subTitle{
    font-size: 1.125rem !important;
  }
  .section-title-main,
  .section-title-main-underline{
    font-size: 2rem !important;
  }
}